import {Dialog, Toast} from "vant";
/**
 * @function handle_error_1_tips
 * @description 卡号过期后报错处理
 */
export const handle_error_1_tips = () => {
    let is_mobile = (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))
    Toast.clear();
    let pc_show = localStorage.getItem("TIHUO_SHOW_PC");
    if(is_mobile || pc_show !== "1"){
        Toast({message: '登录的卡号已过期,请重新登录', duration: 3000});
    }
};
/**
 * @function handle_error_1_tips
 * @description 卡号常规报错
 * @param obj {object}
 */
export const handle_error_2_tips = (obj) =>{
    let is_mobile = (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))
    Toast.clear();
    let pc_show = localStorage.getItem("TIHUO_SHOW_PC");
    if(obj.type === 0){
        if(is_mobile || pc_show !== "1"){
            Toast({message: obj.msg || obj.message, duration: 3000});
        }
    }else if(obj.type === 1){
        if(is_mobile || pc_show !== "1"){
            Dialog.alert({
                title: "提示!!!",
                message: obj.msg || obj.message,
            }).then(()=>{});
        }
    }
}
/**
 * @function handle_error_3_tips
 * @description 请求失败报错
 * @param obj
 */
export const handle_error_3_tips = (obj) => {
    Toast.clear();
    let pc_show = localStorage.getItem("TIHUO_SHOW_PC");
    let is_mobile = (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))
    if(is_mobile  || pc_show !== "1"){
        Toast({message: obj.msg || obj.message, duration: 3000});
    }
}