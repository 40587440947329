const storage_key = "TIHUO_YUN_USE_USER_INFO";

//设置用户信息
export function set_user_info(obj) {
    let str = JSON.stringify(obj);
    localStorage.setItem(storage_key, str);
}

//获取用户信息
export function get_user_info() {
    let str = localStorage.getItem(storage_key);
    return JSON.parse(str || "{}") || {};
}

//清除用户信息
export function clear_user_info() {
    localStorage.removeItem(storage_key);
}

//获取当前登录用户openid
export function get_user_openid(){
    let str = localStorage.getItem(storage_key);
    let obj = JSON.parse(str || "{}") || {};
    return obj?.openid || "";
}

//获取当前登录用户id
export function get_user_id(){
    let str = localStorage.getItem(storage_key);
    let obj = JSON.parse(str || "{}") || {};
    return obj?.id || "";
}
//获取当前登录用户token
export function get_user_token(){
    let str = localStorage.getItem(storage_key);
    let obj = JSON.parse(str || "{}") || {};
    return obj?.token || "";
}